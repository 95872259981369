import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonContent,
  IonModal,
  IonPage,
  IonRow,
  IonToolbar
} from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import './DocumentsList.scss';
import { DATE_FORMAT_MONTH_DATE, HKPlatform } from 'core/constants';
import SearchBar, { filterItems } from 'components/searchbar/SearchBar';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import LoadingIonList from 'core/layout/LoadingIonList';
import { RootState } from 'core/store';
import {
  formatSimpleDate,
  gaBtnClickEvent,
  getDocumentFileTypeIcon
} from 'core/util';
import { fetchAllDocuments } from 'features/shared/documents/DocumentsActions';
import {
  setCurrentDocumentType,
  setSelectedDocument,
  showDocumentsAddModal,
  hideDocumentsAddModal,
  hideDesktopDocumentEditModal,
  showDesktopDocumentEditModal
} from 'features/shared/documents/DocumentsSlice';
import DocumentsAdd from './DocumentsAdd';
import DocumentCard from 'components/cards/document-card/DocumentCard';
import { SearchedDocument } from 'core/types';
import FileIcon from 'assets/icons/file-icon.svg';
import DocumentEdit from './DocumentEdit';

interface DocumentParams {
  type?: string | undefined;
}

const DocumentsList: React.FC = () => {
  const { type } = useParams<DocumentParams>();
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const {
    loading,
    documents,
    types,
    addModalVisible,
    editDesktopModalVisible
  } = useSelector((state: RootState) => state.documents);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const [search, setSearch] = useState('');
  const [documentUrl, setDocumentUrl] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const filteredDocuments = filterItems(documents || [], ['name'], search);

  const getDocumentTypeLabel = (): string => {
    if (!!types) {
      const matchingType = types.find(
        (documentType) => documentType.field === type
      );
      return matchingType!.label;
    } else {
      return 'Manuals';
    }
  };

  const showAddDocumentsModal = (type: string) => {
    gaBtnClickEvent('open_add_documents_modal');
    dispatch(setCurrentDocumentType(type));
    dispatch(showDocumentsAddModal());
  };

  const closeAddDocumentsModal = () => {
    if (!!homeId && !!type) {
      dispatch(fetchAllDocuments(homeId, type));
    }
    dispatch(setCurrentDocumentType(null));
    dispatch(hideDocumentsAddModal());
  };

  const openMobileDocument = (document: SearchedDocument) => {
    dispatch(setSelectedDocument(document));
    history.push(`/documents/group/${document.type}/${document.id}`);
  };

  const openDesktopDocument = (document: SearchedDocument) => {
    dispatch(setSelectedDocument(document));
    dispatch(showDesktopDocumentEditModal());
  };

  const resetDocumentUrl = () => {
    setDocumentUrl('');
    dispatch(hideDesktopDocumentEditModal());
  };

  useEffect(() => {
    if (editDesktopModalVisible === false) {
      if (!!homeId && !!type) {
        dispatch(fetchAllDocuments(homeId, type));
      }
    }
  }, [editDesktopModalVisible]);

  function DesktopDocumentsListView() {
    return (
      <>
        <IonRow className="hk-desktop-digital-documents-list-header">
          <div className="hk-desktop-digital-documents-list-header-container">
            <IonBreadcrumbs className="hk-desktop-digital-documents-list-header-breadcrumbs">
              <IonBreadcrumb href="/documents">Documents</IonBreadcrumb>
              <IonBreadcrumb>{type}</IonBreadcrumb>
            </IonBreadcrumbs>
            <div className="hk-desktop-digital-documents-list-header-right">
              <div className="hk-desktop-digital-documents-list-search">
                <SearchBar
                  value={search}
                  disabled={documents?.length === 0}
                  onSearchChange={(e) => setSearch(e)}
                ></SearchBar>
              </div>
              <div className="hk-desktop-digital-documents-list-header-button">
                <IonButton
                  expand="block"
                  color="primary"
                  strong={true}
                  onClick={() => dispatch(showDocumentsAddModal())}
                >
                  Upload Document
                </IonButton>
              </div>
            </div>
          </div>
        </IonRow>
        <IonContent>
          <LoadingIonList
            loading={loading}
            items={filteredDocuments}
            className={`hk-desktop-digital-document-list ${type}`}
            label={getDocumentTypeLabel()}
          >
            {filteredDocuments.map((document: any, i: any) => {
              const { name, file_url, product_data, created } = document;
              const manufacturer = !!product_data?.manufacturer_name
                ? product_data?.manufacturer_name
                : name;
              return (
                <DocumentCard
                  key={`item-${i}`}
                  title={manufacturer}
                  description={`Uploaded ${formatSimpleDate(
                    created,
                    DATE_FORMAT_MONTH_DATE
                  )}`}
                  image={
                    !!file_url ? getDocumentFileTypeIcon(file_url) : FileIcon
                  }
                  onClick={() => {
                    openDesktopDocument(document);
                  }}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  function MobileDocumentsListView() {
    return (
      <>
        <BackButtonHeader title={getDocumentTypeLabel()} />
        <IonContent fullscreen>
          <SearchBar
            value={search}
            disabled={documents?.length === 0}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList
            items={filteredDocuments}
            className={`hk-digital-document-list ion-margin-horizontal ${type}`}
            label={getDocumentTypeLabel()}
            loading={loading}
          >
            {filteredDocuments.map((document: any, i: any) => {
              const { name, file_url, product_data, created } = document;
              const manufacturer = !!product_data?.manufacturer_name
                ? product_data?.manufacturer_name
                : name;
              return (
                <DocumentCard
                  key={`item-${i}`}
                  title={manufacturer}
                  description={`Uploaded ${formatSimpleDate(
                    created,
                    DATE_FORMAT_MONTH_DATE
                  )}`}
                  image={
                    !!file_url ? getDocumentFileTypeIcon(file_url) : FileIcon
                  }
                  onClick={() => {
                    openMobileDocument(document);
                  }}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="hk-mobile-digital-documents-list-toolbar">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={() => showAddDocumentsModal(type!)}
          >
            Add Document
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-digital-documents-list-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsListView()
        : MobileDocumentsListView()}
      <IonModal
        className="hk-digital-documents-add-modal"
        isOpen={addModalVisible}
        backdropDismiss={false}
        onDidDismiss={() => {
          closeAddDocumentsModal();
        }}
      >
        <DocumentsAdd />
      </IonModal>
      <IonModal
        className="hk-desktop-digital-documents-edit-modal"
        isOpen={editDesktopModalVisible}
        onDidDismiss={() => {
          resetDocumentUrl();
        }}
      >
        <DocumentEdit />
      </IonModal>
    </IonPage>
  );
};

export default DocumentsList;
